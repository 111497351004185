import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "./images/logo.svg";
import ziplogo from "./images/ziplogo.svg";
import img1 from "./images/img1.png";
import opt1 from "./images/opt1.svg";
import opt2 from "./images/opt2.svg";
import opt3 from "./images/opt3.svg";
import opt4 from "./images/opt4.svg";
import remind from "./images/remind.png";
import remind1 from "./images/remind1.png";
import section1 from "./images/section1.png";
import section2 from "./images/section2.png";
import section3 from "./images/section3.svg";
import styles from "./css/home.module.scss";
import Footer from "./Footer.jsx";
const yaml = require("js-yaml");

const baseUrl = "https://tool.yunqiboyuan.com/";
const timestamp = new Date().getTime();
const ymlUrl = `${baseUrl}upload/appversion/latest.yml?v=${timestamp}`;

const Home = () => {
  const navigate = useNavigate();
  const handleNavigate = (path) => {
    navigate(path);
  };

  const [app_name, setApp_name] = useState("");
  const [downloadUrl, setDownloadUrl] = useState("");

  useEffect(() => {
    fetchAndParseYaml(ymlUrl);
  }, []);

  async function fetchAndParseYaml(url) {
    console.log("url", url);
    try {
      // 发送 HTTP GET 请求获取 YAML 文件内容
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.text();
      // 解析 YAML 内容
      const config = yaml.load(data);
      // 读取并打印 path 字段
      const filePath = config?.path;
      console.log("Path:", filePath);
      setDownloadUrl(`${baseUrl}upload/appversion/${filePath}`);
      setApp_name(filePath);
    } catch (e) {
      console.error("读取或解析 YAML 文件时出错:", e);
    }
  }

  const download = () => {
    console.log(" downloadUrl", downloadUrl);
    const a = document.createElement("a");
    a.href = downloadUrl;
    a.download = app_name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  const hostUrl = window.location.href;
  let title = "云启zip解压缩工具"
  if (hostUrl.includes('pdf')){
    title = '云启PDF解压缩工具'
  }
  if (hostUrl.includes('image')){
    title = '云启图片解压缩工具'
  }

  if (hostUrl.includes('video')){
    title = '云启视频解压缩工具'
  }
  

  return (
    <>
      <header className={styles.header}>
        <div className={styles.logo}>
          <img src={logo} title="logo" />
          {/* <span
            style={{ color: "#FF4338", borderColor: "#FF4338" }}
            onClick={() => handleNavigate("/")}
          >
            压缩工具
          </span>
          <span onClick={() => handleNavigate("/homeImage")}>看图工具</span> */}
        </div>

        <div className={styles.nav}>
          <span style={{ color: "#ff4338" ,marginRight:'45px'}} onClick={() => handleNavigate("/")}>首页</span>
          <span onClick={() => handleNavigate("/about")}>关于我们</span>
        </div>
      </header>
      <main className={styles.main}>
        <div className={styles.part1}>
          <div className={styles.titlecontent}>
            <img src={ziplogo} alt="" style={{marginRight:'10px'}} />
            <span className={styles.title}>{title}</span>
          </div>
          <div className={styles.desc}>
            一款快速解压各类文件，支持多种格式文件压缩的专业解压缩工具！
          </div>
          <div className={styles.img1}>
            <img src={img1} alt="" />
          </div>
          <div className={styles.bigBtn} onClick={() => download()}>
            <span className={styles.title1}>立即下载 </span>
            <span className={styles.title2}>适配系统：Win7/8/10/11</span>
          </div>
          <div className={styles.descborder}>
            <img src={remind} alt=""/>
            我们非常尊重用户隐私，我们不会收集用户的任何文件信息，我们完全遵循用户的隐私政策
          </div>
          <div className={styles.optlist}>
            <div
              className={`${styles.opt1}  ${styles.opt}`}
              onClick={() => download()}
            >
              <img src={opt1} alt="" />
              <span>操作方便快捷</span>
            </div>
            <div
              className={`${styles.opt2}  ${styles.opt}`}
              onClick={() => download()}
            >
              <img src={opt2} alt="" />
              <span>高效率解压缩</span>
            </div>
            <div
              className={`${styles.opt3}  ${styles.opt}`}
              onClick={() => download()}
            >
              <img src={opt3} alt="" />
              <span>超全压缩功能</span>
            </div>
            <div
              className={`${styles.opt4}  ${styles.opt}`}
              onClick={() => download()}
            >
              <img src={opt4} alt="" />
              <span>释放存储空间</span>
            </div>
          </div>
        </div>
        <div className={styles.part2}>
          <div
            className={`${styles.section1} ${styles.section}`}
            style={{ paddingLeft: "50px" }}
          >
            <div className={styles.left}>
              <div className={styles.word}>
                <div className={styles.title}>无损压缩 保留原始品质</div>
                <div className={styles.desc}>
                  无损压缩视频、图片、PDF、WORD、PPT等多种文件格式，精细保真，尽享原品质高清视听体验。
                </div>
                <div className={styles.smallBtn}>
                  <div className={styles.downtitle} onClick={() => download()}>
                    立即下载
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.right}>
              <img
                src={section1}
                alt=""
                className={styles.secimg}
                style={{ width: "480px" }}
              />
            </div>
          </div>
          <div
            className={`${styles.section2} ${styles.section}`}
            style={{ paddingRight: "50px" }}
          >
            <div className={styles.left}>
              <img
                src={section2}
                alt=""
                className={styles.secimg}
                style={{ width: "480px" }}
              />
            </div>
            <div className={styles.right}>
              <div className={styles.word}>
                <div className={styles.title}>高速解压缩 省时更省心</div>
                <div className={styles.desc}>
                云启zip解压缩工具以其出色的速度进行解压缩，轻松处理大容量文件或压缩包，快如闪电，助力工作更加顺畅！
                </div>
                <div className={styles.smallBtn}>
                  <div className={styles.downtitle} onClick={() => download()}>
                    立即下载
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`${styles.section3} ${styles.section}`}
            style={{ paddingLeft: "50px" }}
          >
            <div className={styles.left}>
              <div className={styles.word}>
                <div className={styles.title}>快捷解压 支持多格式</div>
                <div className={styles.desc}>
                  支持解压Zip、Rar、7zip、tar、gz等格式，以及预览模式-自定义输出路径，解压完成后自动打开项目文件夹。
                </div>
                <div className={styles.smallBtn}>
                  <div className={styles.downtitle} onClick={() => download()}>
                    立即下载
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.right}>
              <img
                src={section3}
                alt=""
                className={styles.secimg}
                style={{ width: "480px" }}
              />
            </div>
          </div>
        </div>

        <div className={styles.part3}>
          <div className={styles.titlecontent}>
            <span className={styles.title}>{title}</span>
          </div>
          <div className={styles.desc}>
            付费下载、绝无广告、安全可靠、放心下载！
          </div>

          <div className={styles.bigBtn} onClick={() => download()}>
            <span className={styles.title1}>立即下载 </span>
            <span className={styles.title2}>适配系统：Win7/8/10/11</span>
          </div>
          <div className={styles.descborder}>
            <img src={remind1} alt=""/>
            我们非常尊重用户隐私，我们不会收集用户的任何文件信息，我们完全遵循用户的隐私政策
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Home;
