import React from "react";
import styles from './css/home.module.scss'
import { useNavigate } from "react-router-dom";
const Footer = () => {
    const navigate = useNavigate();

    const handleNavigate = (path) => {
      navigate(path);
    };
  return (
    <footer className={styles.footer}>
      <div className={styles.description}>
        <span onClick={() => handleNavigate("/")}>
          产品介绍
        </span>
        <span>|</span>
        <span onClick={() => handleNavigate("/about")}>
          关于我们
        </span>
        <span>|</span>
        <span onClick={() => handleNavigate("/about")}>
          联系我们
        </span>
      </div>
      <div className={styles.address}>
        公司地址：北京市海淀区金隅制造产业园&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        商务合作：business@yunqiboyuan.com
      </div>
      <div className={styles.copyright}>
      CopyrightⒸ2024-{new Date().getFullYear()}Cloud Start Broad Vision.All Rights Reserved.
      </div>
      <div className={styles.record}>
        <a
          href="https://beian.miit.gov.cn/#/Integrated/index"
          className="link"
          target="_blank"
        >
          京ICP备 2024083525号-1  北京云启博远科技有限公司  版权所有
        </a>
      </div>
    </footer>
  );
};

export default Footer;
